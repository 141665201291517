import IcHomeQuest from '@/components/Icons/IcHomeQuest';
import IcHomeStorage from '@/components/Icons/IcHomeStorage';
import IcHouses from '@/components/Icons/IcHouses';
import IcBuildings from '@/components/Icons/header/IcBuildings';
import IcContacts from '@/components/Icons/header/IcContacts';
import IcHelpOutside from '@/components/Icons/header/IcHelpOutside';
import IcHomeFile from '@/components/Icons/header/IcHomeFile';
import IcHomeHands from '@/components/Icons/header/IcHomeHand';
import IcHomePerson from '@/components/Icons/header/IcHomePerson';
import IcHomeQrs from '@/components/Icons/header/IcHomeQrs';
import IcHomeRubble from '@/components/Icons/header/IcHomeRubble';
import IcHomeSuitcase from '@/components/Icons/header/IcHomeSuitcase';
import IcHomeTime from '@/components/Icons/header/IcHomeTime';
import IcNews from '@/components/Icons/header/IcNews';
import IcPaper from '@/components/Icons/header/IcPaper';
import IcSuitcase from '@/components/Icons/header/IcSuitcase';
import { staticLinks } from '@/config/routingLinks';

export const menuData = {
  rent: {
    id: 'rent',
    title: 'Аренда',
    categories: [
      {
        title: 'Объявления',
        subtitle: 'Аренда квартир и домов',
        icon: <IcHouses />,
        path: staticLinks.adverts + '?deal_type=Аренда&rent_type=Длительно',
      },
      {
        title: 'Арендное жилье по льготной стоимости',
        subtitle: 'Необходимые документы для заявки',
        icon: <IcHomeHands />,
        path: staticLinks.housingPrivileges,
      },
      {
        title: 'Арендное жилье по коммерческой цене',
        subtitle: 'Заключение договора коммерческого найма',
        icon: <IcHomeSuitcase />,
        path: staticLinks.rentForm + '?type_document=Жильё+по+коммерческой+цене',
      },
      {
        title: 'Изменение данных нанимателя/семьи',
        subtitle: 'Порядок действий и заявка',
        icon: <IcHomePerson />,
        path:
          staticLinks.rentForm +
          '?type_document=Порядок+действий+при+изменении+данных+нанимателя+и%2Fили+членов+его+семьи.',
      },
      {
        title: 'Расторжение договора найма',
        subtitle: 'Порядок действий и заявка',
        icon: <IcHomeFile />,
        path:
          staticLinks.rentForm + '?type_document=Порядок+действий+при+расторжении+договора+найма',
      },
      {
        title: 'Вопросы',
        subtitle: 'Ответы на часто задаваемые вопросы',
        icon: <IcHomeQuest />,
        path: staticLinks.questions + '#rent',
      },
    ],
  },
  sale: {
    id: 'sale',
    title: 'Продажа',
    categories: [
      {
        title: 'Квартиры от агентства',
        subtitle: 'Продажа и бронирование',
        icon: <IcBuildings />,
        path: staticLinks.bookingHouses,
      },
      {
        title: 'Коммерческая недвижимость',
        subtitle: 'Продажа коммерческих помещений',
        icon: <IcHomeSuitcase />,
        path: staticLinks.saleImmovable,
      },
      {
        title: 'Жилая недвижимость',
        subtitle: 'Продажа квартир и домов',
        icon: <IcHouses />,
        path: staticLinks.adverts + '?deal_type=Продажа',
      },
      {
        title: 'Вопросы',
        subtitle: 'Ответы на часто задаваемые вопросы',
        icon: <IcHomeQuest />,
        path: staticLinks.questions + '#sale',
      },
    ],
  },
  horizont: {
    id: 'horizont',
    title: 'ЖК «Горизонт»',
    categories: [],
  },
  mortgage: {
    id: 'mortgage',
    title: 'Ипотека',
    categories: [
      {
        title: 'Дальневосточная ипотека',
        subtitle: 'Льготная выдача ипотечных кредитов',
        icon: <IcHomeStorage />,
        path: staticLinks.mortgage,
      },
      {
        title: 'Шаг за шагом',
        subtitle: 'Делаем новое жильё ещё более доступным',
        icon: <IcHelpOutside />,
        path: staticLinks.stepByStep,
      },
      {
        title: 'Улучшение жилищных условий',
        subtitle: 'Информация и подать заявку',
        icon: <IcHomeQrs />,
        path: staticLinks.homeQRs,
      },
      {
        title: 'Вопросы',
        subtitle: 'Ответы на часто задаваемые вопросы',
        icon: <IcHomeQuest />,
        path: staticLinks.questions + '#mortgage',
      },
    ],
  },
  housingIssues: {
    id: 'housingIssues',
    title: 'Улучшение жилищных условий',
    categories: [
      {
        title: 'Сахалинская ипотека',
        subtitle: 'Заявка на улучшение жилищных условий',
        icon: <IcHomeQrs />,
        path: staticLinks.homeApplication,
      },
      {
        title: 'Выкуп арендного жилья',
        subtitle: 'Участникам государственных программ',
        icon: <IcHomeRubble />,
        path: staticLinks.homeBuyout,
      },
      {
        title: 'Рассрочка',
        subtitle: 'Гибкие условия оплаты',
        icon: <IcHomeTime />,
        path: staticLinks.apartmentInInstallments,
      },
      {
        title: 'Шаг за шагом',
        subtitle: 'Делаем новое жильё более доступным',
        icon: <IcHelpOutside />,
        path: staticLinks.stepByStep,
      },
      {
        title: 'Квартиры от агентства',
        subtitle: 'Продажа и бронирование',
        icon: <IcBuildings />,
        path: staticLinks.bookingHouses,
      },
      {
        title: 'Вопросы',
        subtitle: 'Ответы на часто задаваемые вопросы',
        icon: <IcHomeQuest />,
        path: staticLinks.questions,
      },
    ],
  },
  additional: {
    id: 'additional',
    title: 'Дополнительно',
    categories: [
      {
        title: 'О нашей компании',
        subtitle: 'Адрес, реквизиты, корп. информация',
        icon: <IcSuitcase />,
        path: staticLinks.aboutCompany,
      },
      {
        title: 'Документы и Заявления',
        subtitle: 'Информация для заемщиков и другое',
        icon: <IcPaper />,
        path: staticLinks.documents,
      },
      {
        title: 'Вопросы',
        subtitle: 'Ответы на часто задаваемые вопросы',
        icon: <IcHomeQuest />,
        path: staticLinks.questions,
      },
      {
        title: 'Новости',
        subtitle: 'Читайте наш интересный блог',
        icon: <IcNews />,
        path: staticLinks.news,
      },
      // {
      //   title: 'Фотоотчеты',
      //   subtitle: 'Подтверждаем слова - делом',
      //   icon: <IcHomePhoto />,
      //   path: staticLinks.photoReports,
      // },
      {
        title: 'Контакты',
        subtitle: 'Номера телефонов, факс, часы приема',
        icon: <IcContacts />,
        path: staticLinks.contacts,
      },
    ],
  },
};
